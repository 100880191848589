import React, { useState } from "react";
import Seo from "../components/SEO/SEO";
import Container from "../components/Container/Container";
import Helmet from "react-helmet";
import InView from "../components/InView";
import { Link } from "gatsby";

import {
	GoogleReCaptchaProvider,
	GoogleReCaptcha,
} from "react-google-recaptcha-v3";

import { useForm } from "react-hook-form";
import axios from "axios";

const July2022 = () => {
	return (
		<>
			<Seo title="Aria club July 2022" />
			<Helmet
				bodyAttributes={{
					class: "no-banner",
				}}
			>
				<meta name="robots" content="noindex" />
			</Helmet>
			<Container>
				<div className="border-b border-grey-default box-border min-h-[75vh]">
					<InView>
						<div className="container flex md:grid md:grid-cols-12 mt-[88px]">
							<div className="md:col-span-9 xl:col-span-7 pb-10 md:pb-[120px]">
								<article className="copy md:pr-10">
									<h1 className="font-serif text-[32px] leading-[1.2]">
										Aria club July 2022
									</h1>
								</article>
								<July2022Form />
							</div>
						</div>
					</InView>
				</div>
			</Container>
		</>
	);
};

const July2022Form = () => {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode: "onSubmit",
		defaultValues: {
			email: "",
		},
	});

	const [serverState, setServerState] = useState({
		submitting: false,
		status: null,
	});

	const handleServerResponse = (ok, msg, form) => {
		setServerState({
			submitting: false,
			status: { ok, msg },
		});
	};
	const handleOnSubmit = (data, e) => {
		e.preventDefault();
		const form = e.target;
		setServerState({ submitting: true });

		const formData = new FormData(form);
		formData.append(
			"g-recaptcha-response",
			serverState["g-recaptcha-response"]
		);

		axios({
			method: "post",
			url: "https://getform.io/f/8a6a9a34-44fe-4246-a4cc-447b8409fde0",
			data: formData,
		})
			.then((r) => {
				handleServerResponse(true);
			})
			.catch((r) => {
				handleServerResponse(false);
			});
	};

	const onVerify = React.useCallback(
		(token) => {
			setServerState((prevState) => ({
				...prevState,
				"g-recaptcha-response": token,
			}));
		},
		[setServerState]
	);

	const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,})$/i;

	return (
		<>
			{serverState.status && serverState.status.ok ? (
				<>
					<div className="md:max-w-[80%] mt-4 md:mt-8">
						<p>
							Check your inbox for your voucher for Aria club’s July 2022 offer!
						</p>
					</div>
				</>
			) : (
				<>
					<GoogleReCaptchaProvider reCaptchaKey="6LdpxosdAAAAAFenROCUFSGek6GYrHvH7MiQ3zVT">
						<form
							className="w-full md:max-w-[70%] mt-4 md:mt-8"
							onSubmit={handleSubmit(handleOnSubmit)}
						>
							<div className="copy copy--container mb-4 md:mb-8 lg:max-w-[80%]">
								<p>
									Please fill out the below details to receive your voucher for
									Aria club’s July 2022 offer.
								</p>
							</div>
							<div className="md:flex md:gap-4">
								<input
									placeholder="First Name*"
									{...register("firstName", { required: true })}
									className={`form-control rounded-none border-b border-grey-default font-sans text-base placeholder-black bg-transparent w-full my-2 lg:my-3 focus:outline-none ${
										errors.firstName ? "border-red-500" : ""
									}`}
								/>
								<input
									placeholder="Last Name*"
									{...register("lastName", { required: true })}
									className={`form-control rounded-none border-b border-grey-default font-sans text-base placeholder-black bg-transparent w-full my-2 lg:my-3 focus:outline-none ${
										errors.lastName ? "border-red-500" : ""
									}`}
								/>
							</div>
							<div>
								<input
									placeholder="Email*"
									{...register("email", {
										required: true,
										pattern: emailRegex,
									})}
									className={`form-control rounded-none border-b border-grey-default font-sans text-base placeholder-black bg-transparent w-full my-2 lg:my-3 focus:outline-none ${
										errors.email ? "border-red-500" : ""
									}`}
								/>
							</div>
							<GoogleReCaptcha onVerify={onVerify} />
							<button
								className="font-sans text-base text-grey-base flex items-center mt-3"
								type="submit"
								disabled={serverState.submitting}
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="10"
									height="11"
									fill="none"
									viewBox="0 0 10 11"
									className="mr-2"
								>
									<path
										fill="#969696"
										d="M9.84 5.268l-5.16 5.18-.64-.64 4.08-4.08H0v-.94h8.12L4.04.728l.64-.64 5.16 5.18z"
									></path>
								</svg>
								{serverState.submitting ? "Submitting" : "Submit"}
							</button>
							<p className="text-grey-base terms mt-4">
								By submitting your enquiry, you indicate that you have read
								our&nbsp;
								<Link
									className="underline hover:text-grey-base hover:no-underline"
									to="/privacy"
								>
									privacy collection notice
								</Link>
								&nbsp;and acknowledge that your personal information will be
								handled in accordance with that notice and our&nbsp;
								<a
									className="underline hover:text-grey-base hover:no-underline"
									target="_blank"
									rel="noreferrer"
									href="/Aria-Privacy-Policy.pdf"
								>
									privacy policy
								</a>
							</p>
						</form>
					</GoogleReCaptchaProvider>
					{serverState.status && !serverState.status.ok && (
						<p className="text-lg font-serif text-red-500">
							We're having an issue submitting form
						</p>
					)}
				</>
			)}
		</>
	);
};

export default July2022;
